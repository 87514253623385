import { GingrClient } from '../clients/GingrClient';
import { BaseService } from './BaseService';
import { GingrClient3 } from '../clients/GingrClient3';
import { SimpleUserModel } from '../components/Pages/Users/simple-user.model';

export class AdminUserService {
  static async approveUser(id: number, userToken: string) {
    let res;

    try {
      res = await GingrClient.post(
        `/admin/approveProfile?id=${id}`,
        {},
        BaseService.getBaseHeaders(userToken)
      );
    } catch (e: any) {
      if (
        e.result[0].message.toLowerCase().includes('Invalid user password') &&
        e.result[0].field.toLowerCase().includes('password')
      ) {
        return {
          message: 'Credentials invalid',
        };
      } else if (e.message.toLowerCase().includes('account is locked')) {
        return {
          message: 'Account Locked',
        };
      } else {
        throw e;
      }
    }

    return res;
  }

  static async refuseUser(userId: number, token: string) {
    try {
      return await GingrClient3.post<boolean>(
        `admin/user/${userId}/refuse`,
        {},
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }

  static async searchUsers(
    query: string,
    token: string
  ): Promise<SimpleUserModel[]> {
    try {
      return await GingrClient3.post<SimpleUserModel[]>(
        `admin/user/search`,
        {
          query,
        },
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return [];
    }
  }

  static async changePassword(
    current_password: string,
    new_password: string,
    token: string
  ) {
    let res;
    try {
      res = await GingrClient.post(
        '/account/change_password',
        {
          current_password: current_password,
          new_password: new_password,
        },
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      console.log('Error while trying to change password: ', e);
    }

    return res;
  }

  static async sendNotificationProfileApproved(token, userId) {
    try {
      return await GingrClient3.post(
        `admin/notifications/profile-approved/${userId}`,
        {},
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }

  static async sendNotificationProfileNotApproved(token, userId) {
    try {
      return await GingrClient3.post(
        `admin/notifications/profile-not-approved/${userId}`,
        {},
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }

  static async sendNotificationProfileVerified(token, userId) {
    try {
      return await GingrClient3.post(
        `admin/notifications/profile-verified/${userId}`,
        {},
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }

  static async sendNotificationProfileNotVerified(token, userId) {
    try {
      return await GingrClient3.post(
        `admin/notifications/profile-not-verified/${userId}`,
        {},
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }
}
