import React from 'react';
import './UserPricing.scss';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PhysicalDetails } from '../../gingrProfileDetails.reducer';
import { OneColumnTable } from '../../OneColumnTable/OneColumnTable';
import { SimpleButton } from '../../../../Revenue/SimpleButton/SimpleButton';

interface GingrPhysicalDetailsProps {
  data?: PhysicalDetails | any;
}

export const UserPricing = ({ data }: GingrPhysicalDetailsProps) => {
  const [t] = useTranslation('translation');
  const pricing = data.pricing;
  const travelTypes = data.traveltypes;
  const locations: string[] = [];
  const travelTo: string[] = [];

  if (pricing.outcall.clientHotel === 1) {
    locations.push(t('WORK_LOCATIONS.CLIENT_HOTEL'));
  }
  if (pricing.outcall.clientHome === 1) {
    locations.push(t('WORK_LOCATIONS.CLIENT_HOME'));
  }
  if (pricing.outcall.events === 1) {
    locations.push(t('WORK_LOCATIONS.EVENTS'));
  }

  travelTypes.map((value) => {
    travelTo.push(value.type);
  });

  return (
    <div className="userPricing">
      <div className="left">
        <div className="tableContainer">
          <h2>Incall</h2>
          {pricing && pricing.incall.interval.length > 0 && (
            <table>
              <tr>
                <th>Time</th>
                <th>Price</th>
              </tr>
              <tbody>
                {pricing.incall.interval &&
                  pricing.incall.interval.map((item) => (
                    <tr>
                      <td>
                        {item.time}{' '}
                        {item.timeHM === 'M'
                          ? t('COMMON.MIN')
                          : t('COMMON.HOUR')}
                      </td>
                      <td>
                        {item.amount} {pricing.incall.symbol}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="currencyContainer">
          <h3>Default Currency</h3>
          <SimpleButton text={pricing.incall.currency} />
        </div>
      </div>
      <Divider
        variant="middle"
        style={{ backgroundColor: '#3a1b37', borderWidth: 1 }}
      />
      <div className="left">
        <div className="tableContainer">
          <h2>Outcall</h2>
          {pricing && pricing.outcall.interval.length > 0 && (
            <table>
              <tr>
                <th>Time</th>
                <th>Price</th>
              </tr>
              <tbody>
                {pricing.outcall.interval &&
                  pricing.outcall.interval.map((item) => (
                    <tr>
                      <td>
                        {item.time}{' '}
                        {item.timeHM === 'M'
                          ? t('COMMON.MIN')
                          : t('COMMON.HOUR')}
                      </td>
                      <td>
                        {item.amount} {pricing.outcall.symbol}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <Divider
          variant="middle"
          style={{ backgroundColor: '#3a1b37', borderWidth: 1, margin: 10 }}
        />
        <div className="secondTableContainer">
          <OneColumnTable head="Location" data={locations} />
          <Divider
            variant="middle"
            style={{ backgroundColor: '#3a1b37', borderWidth: 1, margin: 10 }}
          />
          <OneColumnTable head="I travel to" data={travelTo} />
        </div>
      </div>
    </div>
  );
};
