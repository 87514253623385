import React from 'react';
import { Grid } from '@material-ui/core';
import './UserPersonalDetails.scss';
import { GeneralGingrProfileDetailsState } from '../../gingrProfileDetails.reducer';

interface GingrPersonalDetailsProps {
  data?: GeneralGingrProfileDetailsState | any;
}

export const UserPersonalDetails = ({ data }: GingrPersonalDetailsProps) => {
  return (
    <div className="userPersonalDetailsContainer">
      <div className="description">
        <p>{data.description}</p>
        <span>Description</span>
      </div>
      <div className="personalDetailsContent">
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <p>
              {data.gender === 0
                ? 'Female'
                : data.gender === 1
                ? 'Male'
                : data.gender === 2
                ? 'Trans'
                : ''}
            </p>
            <span>Gender</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {data.sexuality === 0
                ? 'Heterosexual'
                : data.sexuality === 1
                ? 'Homosexual'
                : data.sexuality === 2
                ? 'Bisexual'
                : ''}
            </p>
            <span>Sexuality</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.birthYear}</p>
            <span>Birth Year</span>
          </Grid>

          <Grid item xs={4}>
            <p>
              {data.location && data.location.formatted_address
                ? data.location.formatted_address
                : ''}
            </p>
            <span>Location</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {data &&
                data.languages &&
                data.languages.map((language, index) => {
                  if (index < data.languages.length - 1) {
                    return language.language_name + ', ';
                  } else {
                    return language.language_name;
                  }
                })}
            </p>
            <span>Languages</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.booking_notes}</p>
            <span>Booking notes</span>
          </Grid>

          <Grid item xs={4}>
            <p>{data.occupation}</p>
            <span>Occupation</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.food}</p>
            <span>Food</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.interests}</p>
            <span>Interests</span>
          </Grid>

          <Grid item xs={4}>
            <p>{data.drinks}</p>
            <span>Drinks</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.smoke === 0 ? 'No' : data.smoke === 1 ? 'Yes' : ''}</p>
            <span>Do you smoke?</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.alcohol === 0 ? 'No' : data.alcohol === 1 ? 'Yes' : ''}</p>
            <span>Do you drink?</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
