import React, { useState } from 'react';
import { Header } from '../../../Elements/Header/Header';
import './Clients.scss';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from '../../Dashboard/DashboardItemStats/DashboardItemStats';
import SearchIcon from '@material-ui/icons/Search';
import { Avatar, TextField } from '@material-ui/core';
import CreateNew from '../../../../assets/img/icon/+ plus.svg';
import { makeStyles } from '@material-ui/core/styles';
import Table, { TableColumn } from '../../../GeneralComponents/Table/Table';
import { ChartButton } from '../../Dashboard/ChartButton';
import DeleteIcon from '../../../../assets/img/icon/delete.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralUsersState } from './clients.reducer';
import { fetchUsersAction } from './clients.action';
import { AppState } from '../../../../rootReducer';
import EyeIcon from '../../../../assets/img/icon/eye.svg';
import { Filter, FilterType } from '../../../Elements/ClientsFilter/Filter';
import { useConfirm } from 'material-ui-confirm';
import { ClientService } from './ClientService';
import { UserStatusUtil } from '../../../../utils/Utils';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },

  paginationColor: {
    '& .MuiPaginationItem-root': {
      color: '#f39fff',
      marginTop: 20,
      backgroundColorActive: 'red',
    },
    '& .MuiPagination-ul': {
      backgroundColor: 'transparent',
    },
  },
});

export const Clients: React.FC = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const usersData = useSelector((state: AppState) => state.data.clients);
  const [isUsersFilterOpened, openUsersFilter] = React.useState(false);
  const [filterConditions, setFilterConditions] = useState({
    gender: '',
    proUser: '',
    onlyOnlineUsers: false,
    sexuality: '',
    birthYear: '',
    bookingsNumber: '',
    profileStatus: '',
    verificationStatus: '',
  });
  const updateData = (data: any) => {
    setFilterConditions(data);
    dispatch(fetchUsersAction(data));
  };
  React.useEffect(() => {
    dispatch(fetchUsersAction(filterConditions));
  }, []);

  // @ts-ignore
  const [users, setUsers] = React.useState<GeneralUsersState[]>(
    usersData.users.users
  );
  // @ts-ignore
  const [filteredUsers, setFilteredUsers] =
    React.useState<GeneralUsersState[]>(users);

  React.useEffect(() => {
    if (usersData && usersData.users !== 'INCOMPLETE') {
      // @ts-ignore
      setUsers(usersData.users.users);
      // @ts-ignore
      setFilteredUsers(usersData.users.users);
    }
  }, [usersData]);
  const history = useHistory();
  const navigateToUsersDetails = (id: number) => {
    history.push(`/users/clients/${id}/personal-details`);
  };

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const handleDelete = (id: number) => {
    confirm({
      title: 'Are you sure you want to confirm?',
      description: `This will permanently delete the user.`,
    })
      .then(() => {
        deleteUser(id);
        setTimeout(() => {
          dispatch(fetchUsersAction());
        }, 300);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const deleteUser = async (id: number) => {
    await ClientService.deleteUser(
      id,
      {
        token: token,
      },
      {}
    ).then();
  };

  const columns: TableColumn[] = [
    {
      columnName: 'id',
      columnLabel: 'Id',
      sortable: true,
      textAlign: 'center',
    },
    {
      columnName: 'displayname',
      columnLabel: 'Display name',
      sortable: true,
      textAlign: 'left',
      dataFormat: (value: any, data: any) => (
        <div className="avatarContainer">
          <Avatar className="avatarPosition" src={data.avatarThumb} />
          <p style={{ margin: 0, padding: 0 }}>{value}</p>
        </div>
      ),
    },
    {
      columnName: 'profile_approved',
      columnLabel: 'Review',
      sortable: true,
      textAlign: 'center',
      dataFormat: (value: any) => UserStatusUtil[value].name,
    },
    {
      columnName: 'gender',
      columnLabel: 'Gender',
      sortable: false,
      textAlign: 'center',
      dataFormat: (value: any) =>
        value === 0
          ? 'Female'
          : value === 1
          ? 'Male'
          : value === 2
          ? 'Trans'
          : '',
    },
    {
      columnName: 'sexuality',
      columnLabel: 'Sexuality',
      sortable: false,
      textAlign: 'center',
      dataFormat: (value: any) =>
        value === 0
          ? 'Heterosexual'
          : value === 1
          ? 'Homosexual'
          : value === 2
          ? 'Bisexual'
          : '',
    },
    {
      columnName: 'bookings',
      columnLabel: 'Bookings',
      sortable: true,
      textAlign: 'center',
    },
    {
      columnName: 'proUser',
      columnLabel: 'Subscription',
      sortable: true,
      textAlign: 'center',
      dataFormat: (value: any) => (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <ChartButton text={value ? 'Premium' : 'Free'} isSelected={true} />
        </div>
      ),
    },
    {
      columnName: 'created_at',
      columnLabel: 'Registered',
      sortable: true,
      textAlign: 'center',
    },
    {
      columnName: 'online',
      columnLabel: 'Status',
      sortable: true,
      textAlign: 'center',
      dataFormat: (value: any) => (
        <div className="userStatusContainer">
          <div className="dotContainer">
            <p className={value === true ? 'onlineDot' : 'offlineDot'}>.</p>
          </div>
          <p className="text ml-small">{value ? 'Online' : 'Offline'}</p>
        </div>
      ),
    },
    {
      columnName: 'id',
      columnLabel: '',
      sortable: false,
      textAlign: 'center',
      dataFormat: (value: any) => (
        <EyeIcon
          onClick={() => navigateToUsersDetails(value)}
          className="editIcon"
        />
      ),
    },
    {
      columnName: 'id',
      columnLabel: '',
      sortable: false,
      textAlign: 'center',
      dataFormat: (value: any) => (
        <DeleteIcon
          onClick={() => handleDelete(value)}
          className="deleteIcon"
        />
      ),
    },
  ];

  const requestSearch = (searchedVal: string) => {
    // @ts-ignore
    let filteredRows = JSON.parse(JSON.stringify(users));
    filteredRows = filteredRows.filter((row) => {
      if (row.displayname === null) {
        row.displayname = '';
      }
      return row.displayname.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilteredUsers(filteredRows);
  };
  const classes = useStyles();
  // @ts-ignore
  return (
    <div>
      <Header title={'Clients'} />
      <div className="activityLogContainer">
        <div className="statsContainer">
          <DashboardItemStats number="442" text="Bookings" />
          <DashboardItemStats
            number="444"
            text="Settings Changes"
            negativeNumber={23}
            type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
            number="33"
            text="Reports"
            positiveNumber={23}
            type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
            number="2,394"
            text="Searches"
            type={DashboardItemStatsType.PERCENTAGE}
            negativeNumber={23}
          />
          <DashboardItemStats
            number="444"
            type={DashboardItemStatsType.PERCENTAGE}
            text="Comments of reviews"
            negativeNumber={23}
          />
        </div>
        <div className="searchContainer">
          <div className="search">
            <SearchIcon className="searchIcon" />
            <TextField
              InputProps={{
                style: {
                  width: 500,
                  color: '#8e818d',
                  fontSize: 15,
                  marginLeft: 16,
                },
                classes,
              }}
              id="standard-basic"
              placeholder="Search Clients by display name"
              autoComplete={'off'}
              onChange={(searchVal) => requestSearch(searchVal.target.value)}
            />
          </div>
          <div className="filter">
            <Filter
              type={FilterType.CLIENT}
              isOpened={isUsersFilterOpened}
              updateData={updateData}
            />
            <p className="filterText">Filter</p>
            <CreateNew className="filterIcon" />
            <p className="filterText">Create new</p>
          </div>
        </div>

        <div className="logsContainer">
          <Table
            columns={columns}
            data={filteredUsers}
            noDataFoundText="No Data Found!"
            rowsPerPage={10}
            defaultSort={{ orderBy: 'id', order: 'desc' }}
          />
        </div>
      </div>
    </div>
  );
};
