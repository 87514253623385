import {
  GeneralLiveUsersCountState,
  GeneralUsersCountState,
} from './dashboard.reducer';
import { GingrClient } from '../../../clients/GingrClient';
import { BaseService } from '../../../service/BaseService';

const ProductServicePaths = {
  USERS_COUNT: '/admin/statistics/usersCount',
  USERS_COUNT_LIVE: '/admin/statistics/usersCount?live=true',
  USERS_PENDING_COUNT: '/admin/statistics/pendingCounts',
};

export class DashboardService {
  static async getUsersCount(credentials: {
    token: string;
  }): Promise<GeneralUsersCountState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.USERS_COUNT,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async getLiveUsersCount(credentials: {
    token: string;
  }): Promise<GeneralLiveUsersCountState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.USERS_COUNT_LIVE,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async getUsersPendingForApprovalCount(credentials: {
    token: string;
  }): Promise<{
    total: number;
    clientpendingApproval: number;
    gingrpendingApproval: number;
    establishmentpendingApproval: number;
    agencypendingApproval: number;
  }> {
    const { result } = await GingrClient.get(
      ProductServicePaths.USERS_PENDING_COUNT,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
}
