import React from 'react';
import { Header } from '../../Elements/Header/Header';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from './DashboardItemStats/DashboardItemStats';
import './Dashboard.scss';
import { dashboardChartData as data } from './dummyData';
import { ChartButton } from './ChartButton';
import { ActivityItem } from './ActivityItem/ActivityItem';
import { GingrChart } from './GingrChart';
import { Calendar } from './Calendar/Calendar';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import {
  GeneralLiveUsersCountState,
  GeneralUsersCountState,
} from './dashboard.reducer';
import {
  fetchLiveUsersAction,
  fetchUsersCountAction,
} from './dashboard.action';

const initStateItems: {
  client: boolean;
  gingrs: boolean;
  establishments: boolean;
  agencies: boolean;
  timeFrames: {
    today: number;
    thisMonth: number;
    thisYear: number;
  };
} = {
  client: false,
  gingrs: false,
  establishments: false,
  agencies: false,
  timeFrames: {
    today: 0,
    thisMonth: 0,
    thisYear: 0,
  },
};

export const Dashboard: React.FC = () => {
  const [selectedChartItem, setSelectedChartItem] =
    React.useState(initStateItems);
  const dispatch = useDispatch();
  const [userCount, setUsersCount] = React.useState<GeneralUsersCountState>({
    total: 0,
    gingr: 0,
    client: 0,
    agency: 0,
    establishment: 0,
    timeFrames: {
      today: 0,
      thisMonth: 0,
      thisYear: 0,
    },
    increasePercentage: { year: 0, month: 0, day: 0 },
  });
  const [liveUsersCount, setLiveUsersCount] =
    React.useState<GeneralLiveUsersCountState>({
      total: 0,
      gingr: 0,
      client: 0,
      agency: 0,
      establishment: 0,
      pendingForApproval: 0,
    });
  React.useEffect(() => {
    dispatch(fetchUsersCountAction());
  }, []);
  React.useEffect(() => {
    dispatch(fetchLiveUsersAction());
  }, []);

  const dashboardData = useSelector((state: AppState) => state.data.dashboard);
  const dashboardLiveUsersData = useSelector(
    (state: AppState) => state.data.dashboard
  );

  React.useEffect(() => {
    if (
      dashboardData &&
      dashboardData.statistics &&
      dashboardData.statistics.usersCount !== 'INCOMPLETE'
    ) {
      setUsersCount(dashboardData.statistics.usersCount);
    }
  }, [dashboardData]);

  React.useEffect(() => {
    if (
      dashboardLiveUsersData &&
      dashboardLiveUsersData.statistics &&
      dashboardLiveUsersData.statistics.liveUsersCount !== 'INCOMPLETE'
    ) {
      setLiveUsersCount(dashboardLiveUsersData.statistics.liveUsersCount);
    }
  }, [dashboardLiveUsersData]);

  return (
    <div>
      <Header title={'Dashboard'} />
      <div className="dashboardStatsContainer">
        <DashboardItemStats
          number={liveUsersCount.total}
          text="Total Live Users"
        />
        <DashboardItemStats
          number={liveUsersCount.client}
          text="Total Live Clients"
        />
        <DashboardItemStats
          number={liveUsersCount.gingr}
          text="Total Live Gingrs"
        />
        <DashboardItemStats
          number={liveUsersCount.establishment}
          text="Total Live Establishments"
        />
        <DashboardItemStats
          number={liveUsersCount.agency}
          text="Total Live Agencies"
        />
        <DashboardItemStats number="2,394" text="Total bookings" />
        <DashboardItemStats
          className="warning-number"
          number={
            liveUsersCount && liveUsersCount.pendingForApproval
              ? liveUsersCount.pendingForApproval
              : 0
          }
          text="Profiles Pending Approval"
        />
      </div>
      <div className="tabsAndFilterContainer">
        <div className="divider">
          <h2 className="title">Registrations: </h2>
          <ChartButton
            text="Client"
            isSelected={selectedChartItem.client}
            onClick={() =>
              setSelectedChartItem((prevState) => {
                return {
                  ...prevState,
                  client: !prevState.client,
                };
              })
            }
          />
          <ChartButton
            text="Gingrs"
            isSelected={selectedChartItem.gingrs}
            onClick={() =>
              setSelectedChartItem((prevState) => {
                return {
                  ...prevState,
                  gingrs: !prevState.gingrs,
                };
              })
            }
          />
          <ChartButton
            text="Establishments"
            isSelected={selectedChartItem.establishments}
            onClick={() =>
              setSelectedChartItem((prevState) => {
                return {
                  ...prevState,
                  establishments: !prevState.establishments,
                };
              })
            }
          />
          <ChartButton
            text="Agencies"
            isSelected={selectedChartItem.agencies}
            onClick={() =>
              setSelectedChartItem((prevState) => {
                return {
                  ...prevState,
                  agencies: !prevState.agencies,
                };
              })
            }
          />
        </div>
        <div className="divider">
          <Calendar />
        </div>
      </div>
      <div className="headerChartContainer">
        <GingrChart data={data} />
      </div>
      <div className="dashboardStatsContainer">
        <DashboardItemStats
          number={userCount?.client.toString()}
          text="Total Registered Clients"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={userCount.gingr.toString()}
          text="Total Registered Gingrs"
          negativeNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={userCount.establishment.toString()}
          text="Total Registered Establishments"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={userCount.agency.toString()}
          text="Total Registered Agencies"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
      </div>
      <div className="dashboardStatsContainer">
        <DashboardItemStats
          number={userCount.total.toString()}
          text="Total registration"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={userCount.timeFrames.thisYear.toString()}
          text="This year’s registration"
          percentage={userCount.increasePercentage.year}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={userCount.timeFrames.thisMonth.toString()}
          text="This month’s registration"
          percentage={userCount.increasePercentage.month}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={userCount.timeFrames.today.toString()}
          text="Today's registration"
          percentage={userCount.increasePercentage.day}
          type={DashboardItemStatsType.PERCENTAGE}
        />
      </div>
      <p className="universalTitle">Activity</p>
      <div className="activityContainer">
        <div className="itemsDivider">
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
        </div>
        <div className="itemsDivider">
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
        </div>
      </div>
      <p className="universalTitle">Revenue</p>
      <div className="headerChartContainer">
        <GingrChart data={data} />
      </div>
      <div className="dashboardStatsContainer">
        <DashboardItemStats
          number="442"
          text="Total revenue"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number="1,492"
          text="This year’s revenue"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number="444"
          text="This month’s revenue"
          negativeNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number="33"
          text="Today's revenue"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
      </div>
    </div>
  );
};
