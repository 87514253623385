import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../rootReducer';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { GingrProfileDetailsService } from './GingrProfileDetailsService';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import {
  Avatar,
  GeneralGingrProfileDetailsState,
  Language,
  Location,
  PhysicalDetails,
  Pricing,
  Traveltype,
} from './gingrProfileDetails.reducer';
import {
  Availability,
  Service,
} from '../AgenciesDetails/agencyProfileDetails.reducer';

export type GingrProfileDetailsAction =
  | 'ADD_GINGR_PROFILE_DETAILS'
  | 'CLEAR_GINGR_PROFILE_DETAILS';

export interface AddGingrProfileDetailsAction
  extends Action<GingrProfileDetailsAction> {
  type: 'ADD_GINGR_PROFILE_DETAILS';
  id: number;
  slug: string;
  email: string;
  proUser: boolean;
  displayname: string;
  dateOfBirth: Date;
  birthYear: number;
  gender: number;
  nationality: string;
  sexuality: number;
  food: string;
  drinks: string;
  interests: string;
  smoke: string;
  alcohol: string;
  createdAt: Date;
  phonePrefix: string;
  phoneNumber: string;
  occupation: string;
  profileStatus: number;
  description: string;
  booking_notes: string;
  traveltypes: Traveltype[];
  pricing: Pricing;
  availability: Availability[];
  services: Service[];
  languages: Language[];
  location: Location;
  physicalDetails: PhysicalDetails;
  avatar: Avatar;
  verified: number
}

export interface ClearGingrProfileDetailsAction
  extends Action<GingrProfileDetailsAction> {
  type: 'CLEAR_GINGR_PROFILE_DETAILS';
}

export const addGingrProfileDetails = (
  id: number,
  slug: string,
  email: string,
  proUser: boolean,
  displayname: string,
  dateOfBirth: Date,
  birthYear: number,
  gender: number,
  nationality: string,
  sexuality: number,
  food: string,
  drinks: string,
  interests: string,
  smoke: string,
  alcohol: string,
  createdAt: Date,
  phonePrefix: string,
  phoneNumber: string,
  occupation: string,
  profileStatus: number,
  description: string,
  booking_notes: string,
  traveltypes: Traveltype[],
  pricing: Pricing,
  availability: Availability[],
  services: Service[],
  languages: Language[],
  location: Location,
  physicalDetails: PhysicalDetails,
  avatar: Avatar,
  verified: number
): AddGingrProfileDetailsAction => ({
  type: 'ADD_GINGR_PROFILE_DETAILS',
  id,
  slug,
  email,
  proUser,
  displayname,
  dateOfBirth,
  birthYear,
  gender,
  nationality,
  sexuality,
  food,
  drinks,
  interests,
  smoke,
  alcohol,
  createdAt,
  phonePrefix,
  phoneNumber,
  occupation,
  profileStatus,
  description,
  booking_notes,
  traveltypes,
  pricing,
  availability,
  services,
  languages,
  location,
  physicalDetails,
  avatar,
  verified
});

export const clearAgentInformation = (): ClearGingrProfileDetailsAction => ({
  type: 'CLEAR_GINGR_PROFILE_DETAILS',
});

export const fetchGingrProfileDetailsAction =
  (id: any): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('GINGR_PROFILE_DETAILS'));
    let response: GeneralGingrProfileDetailsState;
    const credentials = getCredentials(getState());
    const id1 = id;
    try {
      if (id1 !== 0 && id1 !== undefined) {
        response = await GingrProfileDetailsService.getGingrProfileDetails(
          credentials,
          id1
        );
        const {
          id,
          slug,
          email,
          proUser,
          displayname,
          dateOfBirth,
          birthYear,
          gender,
          nationality,
          sexuality,
          food,
          drinks,
          interests,
          smoke,
          alcohol,
          createdAt,
          phonePrefix,
          phoneNumber,
          occupation,
          profileStatus,
          description,
          booking_notes,
          traveltypes,
          pricing,
          availability,
          services,
          languages,
          location,
          physicalDetails,
          avatar,
          verified
        } = response;
        dispatch(
          addGingrProfileDetails(
            id,
            slug,
            email,
            proUser,
            displayname,
            dateOfBirth,
            birthYear,
            gender,
            nationality,
            sexuality,
            food,
            drinks,
            interests,
            smoke,
            alcohol,
            createdAt,
            phonePrefix,
            phoneNumber,
            occupation,
            profileStatus,
            description,
            booking_notes,
            traveltypes,
            pricing,
            availability,
            services,
            languages,
            location,
            physicalDetails,
            avatar,
              verified
          )
        );
      }
    } catch (error) {
      console.log('Error during add users count action: ', error);
    }
    dispatch(loadComplete('GINGR_PROFILE_DETAILS_FETCH'));
  };
