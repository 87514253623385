import { Action } from 'redux';
import produce from 'immer';
import {
  AddLiveUsersCountAction,
  AddUsersCountAction,
} from './dashboard.action';

export interface GeneralUsersCountState {
  total: number;
  client: number;
  gingr: number;
  establishment: number;
  agency: number;
  timeFrames: TimeFrames;
  increasePercentage: IncreasePercentage;
}
export interface TimeFrames {
  today: number;
  thisMonth: number;
  thisYear: number;
}
export interface IncreasePercentage {
  year: number;
  month: number;
  day: number;
}

export interface GeneralLiveUsersCountState {
  total: number;
  client: number;
  gingr: number;
  establishment: number;
  agency: number;
  pendingForApproval: number;
}

export type UsersCountAction = 'ADD_USERS_COUNT' | 'CLEAR_USERS_COUNT';

export type UsersCountState = 'INCOMPLETE' | GeneralUsersCountState;

export type LiveUsersCountAction =
  | 'ADD_LIVE_USERS_COUNT'
  | 'CLEAR_LIVE_USERS_COUNT';

export type LiveUsersCountState = 'INCOMPLETE' | GeneralLiveUsersCountState;

export const dashboardReducer = (
  state: UsersCountState = 'INCOMPLETE',
  action: Action<UsersCountAction>
): UsersCountState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_USERS_COUNT':
        const addUsersCountAction = action as AddUsersCountAction;
        const {
          total,
          client,
          gingr,
          establishment,
          agency,
          timeFrames,
          increasePercentage,
        } = addUsersCountAction;
        return {
          total,
          client,
          gingr,
          establishment,
          agency,
          timeFrames,
          increasePercentage,
        };
      case 'CLEAR_USERS_COUNT':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};

export const dashboardLiveUsersReducer = (
  state: LiveUsersCountState = 'INCOMPLETE',
  action: Action<LiveUsersCountAction>
): LiveUsersCountState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_LIVE_USERS_COUNT':
        const addLiveUsersCountAction = action as AddLiveUsersCountAction;
        const {
          total,
          client,
          gingr,
          establishment,
          agency,
          pendingForApproval,
        } = addLiveUsersCountAction;
        return {
          total,
          client,
          gingr,
          establishment,
          agency,
          pendingForApproval,
        };
      case 'CLEAR_LIVE_USERS_COUNT':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
