import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { loadComplete, loadStarted } from '../../../shared/loading.action';
import { DashboardService } from './DashboardService';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import {
  GeneralLiveUsersCountState,
  GeneralUsersCountState,
  IncreasePercentage,
  TimeFrames,
} from './dashboard.reducer';

export type DashboardActionType = 'USERS_COUNT';

export type UsersCountAction = 'ADD_USERS_COUNT' | 'CLEAR_USERS_COUNT';

export type DashboardLiveUsersActionType = 'LIVE_USERS_COUNT';

export type LiveUsersCountAction =
  | 'ADD_LIVE_USERS_COUNT'
  | 'CLEAR_LIVE_USERS_COUNT';

export interface AddUsersCountAction extends Action<UsersCountAction> {
  type: 'ADD_USERS_COUNT';
  total: number;
  client: number;
  gingr: number;
  establishment: number;
  agency: number;
  timeFrames: TimeFrames;
  increasePercentage: IncreasePercentage;
}

export interface ClearUsersCountAction extends Action<UsersCountAction> {
  type: 'CLEAR_USERS_COUNT';
}

export interface AddLiveUsersCountAction extends Action<LiveUsersCountAction> {
  type: 'ADD_LIVE_USERS_COUNT';
  total: number;
  client: number;
  gingr: number;
  establishment: number;
  agency: number;
  pendingForApproval: number;
}

export interface ClearLiveUsersCountAction
  extends Action<LiveUsersCountAction> {
  type: 'CLEAR_LIVE_USERS_COUNT';
}

export const addUsersCount = (
  total: number,
  client: number,
  gingr: number,
  establishment: number,
  agency: number,
  timeFrames: TimeFrames,
  increasePercentage: IncreasePercentage
): AddUsersCountAction => ({
  type: 'ADD_USERS_COUNT',
  total,
  client,
  gingr,
  establishment,
  agency,
  timeFrames,
  increasePercentage,
});

export const clearAgentInformation = (): ClearUsersCountAction => ({
  type: 'CLEAR_USERS_COUNT',
});

export const addLiveUsersCount = (
  total: number,
  client: number,
  gingr: number,
  establishment: number,
  agency: number,
  pendingForApproval: number
): AddLiveUsersCountAction => ({
  type: 'ADD_LIVE_USERS_COUNT',
  total,
  client,
  gingr,
  establishment,
  agency,
  pendingForApproval,
});

export const clearLiveUsersAgentInformation =
  (): ClearLiveUsersCountAction => ({
    type: 'CLEAR_LIVE_USERS_COUNT',
  });

export const fetchUsersCountAction =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('USER_COUNT'));
    let response: GeneralUsersCountState;
    const credentials = getCredentials(getState());
    try {
      response = await DashboardService.getUsersCount(credentials);
      const {
        total,
        gingr,
        client,
        agency,
        establishment,
        timeFrames,
        increasePercentage,
      } = response;
      dispatch(
        addUsersCount(
          total,
          client,
          gingr,
          establishment,
          agency,
          timeFrames,
          increasePercentage
        )
      );
    } catch (error) {
      console.log('Error during add users count action: ', error);
    }
    dispatch(loadComplete('USERS_COUNT_FETCH'));
  };

export const fetchLiveUsersAction =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('LIVE_USER_COUNT'));
    let response: GeneralLiveUsersCountState;
    const credentials = getCredentials(getState());
    try {
      response = await DashboardService.getLiveUsersCount(credentials);
      const {
        total,
        gingr,
        client,
        agency,
        establishment,
        pendingForApproval,
      } = response;
      dispatch(
        addLiveUsersCount(
          total,
          client,
          gingr,
          establishment,
          agency,
          pendingForApproval
        )
      );
    } catch (error) {
      console.log('Error during add live users count action: ', error);
    }
    dispatch(loadComplete('LIVE_USERS_COUNT_FETCH'));
  };
