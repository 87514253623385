import React from 'react';
import { Grid } from '@material-ui/core';
import './UserPersonalDetails.scss';
import { GeneralUserProfileDetailsState } from './userProfileDetails.reducer';

interface UserPersonalDetailsProps {
  data?: GeneralUserProfileDetailsState | any;
}

export const UserPersonalDetails = ({ data }: UserPersonalDetailsProps) => {
  const userProfileDetails = data;

  return (
    <div className="userPersonalDetailsContainer">
      <div className="description">
        <p>{userProfileDetails.description}</p>
        <span>Description</span>
      </div>
      <div className="personalDetailsContent">
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.gender === 0
                ? 'Female'
                : userProfileDetails.gender === 1
                ? 'Male'
                : userProfileDetails.gender === 2
                ? 'Trans'
                : ''}
            </p>
            <span>Gender</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.sexuality === 0
                ? 'Heterosexual'
                : userProfileDetails.sexuality === 1
                ? 'Homosexual'
                : userProfileDetails.sexuality === 2
                ? 'Bisexual'
                : ''}
            </p>
            <span>Sexuality</span>
          </Grid>
          <Grid item xs={4}>
            <p>{userProfileDetails.birthYear}</p>
            <span>Birth Year</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.height}
              {userProfileDetails.height && 'CM'}
            </p>
            <span>Height</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.weight}
              {userProfileDetails.weight && 'KG'}
            </p>
            <span>Weight</span>
          </Grid>
          <Grid item xs={4}>
            <p>{userProfileDetails.genitaliaArea}</p>
            <span>Genital area</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.penisSize &&
              userProfileDetails.penisSize !== 0
                ? userProfileDetails.penisSize
                : ''}
              {userProfileDetails.penisSize &&
              userProfileDetails.penisSize !== 0
                ? 'CM'
                : ''}
            </p>
            <span>Penis Size (Male)</span>
          </Grid>
          <Grid item xs={4}>
            {(userProfileDetails.hear &&
              userProfileDetails.hear.toLowerCase() === 'others' && (
                <>
                  <p>{userProfileDetails.hearText}</p>
                  <span>How did you hear about us?</span>
                </>
              )) || (
              <>
                <p>{userProfileDetails.hear}</p>
                <span>How did you hear about us?</span>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
