import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { loadComplete, loadStarted } from '../../../shared/loading.action';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { GingrProfileDetailsService } from './GingrDetails/GingrProfileDetailsService';
import {APPROVAL_STATUS, NOTIFICATION_SELFIES} from './GingrDetails/GingrDetails';

export type UsersAssetsNotificationTypes =
  | 'COUNT_USER_PHOTOS_TO_APPROVE_FAIL'
  | 'COUNT_USER_VIDEOS_TO_APPROVE_FAIL'
  | 'GET_USER_PHOTOS_TO_APPROVE_COUNT'
  | 'GET_USER_VIDEOS_TO_APPROVE_COUNT'
  | 'GET_USER_VERIFICATION_ITEMS_TO_APPROVE'  ;

export interface CountUserPhotosToApproveAction
  extends Action<UsersAssetsNotificationTypes> {
  type: 'GET_USER_PHOTOS_TO_APPROVE_COUNT';
  value: number;
}

export interface CountUserVideosToApproveAction
  extends Action<UsersAssetsNotificationTypes> {
  type: 'GET_USER_VIDEOS_TO_APPROVE_COUNT';
  value: number | string;
}

export interface CountUserVerificationToApprove extends  Action<UsersAssetsNotificationTypes> {
  type: 'GET_USER_VERIFICATION_ITEMS_TO_APPROVE';
  value : number | string;
}

export const countSelfiesToBeApprove = (
    value: number
): CountUserVerificationToApprove => ({
  type: 'GET_USER_VERIFICATION_ITEMS_TO_APPROVE',
  value,
});


export const countImagesToBeApprove = (
  value: number
): CountUserPhotosToApproveAction => ({
  type: 'GET_USER_PHOTOS_TO_APPROVE_COUNT',
  value,
});

export const countVideosToBeApprove = (
  value: number
): CountUserVideosToApproveAction => ({
  type: 'GET_USER_VIDEOS_TO_APPROVE_COUNT',
  value,
});

export const countUserPhotosToBeApproved =
  (
    id: number | string,
    countType: 'PHOTOS' | 'VIDEOS' | 'SELFIES'
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const credentials = getCredentials(getState());
    const requestByCountType = {
      PHOTOS: 'getGingrImages',
      VIDEOS: 'getGingrVideos',
      SELFIES: 'getGingrVerification'
    }

    const result = requestByCountType[countType];

    GingrProfileDetailsService[result](
      credentials,
      id,
      APPROVAL_STATUS.TO_BE_APPROVE
    )
      .then((result) => {
        if (result) {
          switch (countType) {
            case 'PHOTOS':
              dispatch(
                countImagesToBeApprove(
                  result.filter((value) => value.approved === APPROVAL_STATUS.TO_BE_APPROVE).length
                )
              );
              break;
            case 'VIDEOS':
              dispatch(
                countVideosToBeApprove(
                  result.filter((value) => value.approved === APPROVAL_STATUS.TO_BE_APPROVE).length
                )
              );
              break;
            case 'SELFIES' : dispatch(
                countSelfiesToBeApprove(
                    result.filter((value) => value.status === 'PENDING').length
                )
            );
              break;
            default:
          }
        }
      })
      .catch((err) => {
        console.log(
          'Error during GET_USER_PHOTOS_TO_APPROVE_COUNT action: ',
          err
        );
        dispatch(loadComplete('GET_USER_PHOTOS_TO_APPROVE_COUNT'));
      })
      .finally(() => {
        dispatch(loadComplete('GET_USER_PHOTOS_TO_APPROVE_COUNT'));
      });
  };
