import { Action } from 'redux';
import produce from 'immer';
import { AddGingrProfileDetailsAction } from './gingrProfileDetails.action';

export interface GeneralGingrProfileDetailsState {
  id: number;
  slug: string;
  email: string;
  proUser: boolean;
  displayname: string;
  dateOfBirth: Date;
  birthYear: number;
  gender: number;
  nationality: string;
  sexuality: number;
  food: string;
  drinks: string;
  interests: string;
  smoke: string;
  alcohol: string;
  createdAt: Date;
  phonePrefix: string;
  phoneNumber: string;
  occupation: string;
  profileStatus: number;
  description: string;
  booking_notes: string;
  traveltypes: Traveltype[];
  pricing: Pricing;
  availability: Availability[];
  services: Service[];
  languages: Language[];
  location: Location;
  physicalDetails: PhysicalDetails;
  avatar: Avatar;
  verified: number;
}

export interface Traveltype {
  id: number;
  type: string;
}

export interface Pricing {
  incall: Incall;
  outcall: Outcall;
}

export interface Incall {
  id: number;
  active: number;
  amountPph: number;
  currency: string;
  furtherHourCheck: number;
  furtherHours: number;
  locationMyEstablisment: number;
  locationMyPlace: number;
  ppH: any;
  symbol: string;
  admissionfee: any;
  interval: Interval[];
}

export interface Interval {
  pp: string;
  amount: number;
  timeHM: string;
  time: string;
}

export interface Outcall {
  id: number;
  active: number;
  amountPph: number;
  currency: string;
  furtherHourCheck: number;
  furtherHours: number;
  locationMyEstablisment: any;
  locationMyPlace: any;
  ppH: any;
  symbol: string;
  clientHome: number;
  clientHotel: number;
  events: number;
  interval: Interval[];
}

export interface Availability {
  day: number;
  from: string;
  to: string;
  from2: any;
  to2: any;
  from3: any;
  to3: any;
  enabled: number;
  isDefaultGingr: number;
  fullDay: boolean;
}

export interface Service {
  id: number;
  description: string;
  name: string;
  service_name: string;
  price?: number;
  currency?: string;
  symbol?: string;
  excerpt: string;
  ambit: string;
  activity: any;
}

export interface Avatar {
  id: number;
  type: string;
  media: string;
  thumb: string;
  thumbblur: string;
}

export interface PhysicalDetails {
  height: string;
  weight: string;
  ethnicity: string;
  hairColour: string;
  eyeColour: string;
  breastImplant: number;
  genitaliaArea: string;
  brest: string;
  stomach: string;
  waist: string;
  cupSize: string;
  tattoo: any;
  piercingFace: any;
  piercingIntimate: any;
  bodyType: BodyType[];
}

export interface Language {
  id: number;
  language_name: string;
  rating: number;
}

export interface Location {
  formatted_address: string;
  longitude: number;
  latitude: number;
  country: string;
  city: string;
  isArea: number;
  have_working_address: number;
}

export interface BodyType {
  id: number;
  name: string;
}

export type GingrProfileDetailsAction =
  | 'ADD_GINGR_PROFILE_DETAILS'
  | 'CLEAR_GINGR_PROFILE_DETAILS';

export type GingrProfileDetailsState =
  | 'INCOMPLETE'
  | GeneralGingrProfileDetailsState;

export const gingrProfileDetailsReducer = (
  state: GingrProfileDetailsState = 'INCOMPLETE',
  action: Action<GingrProfileDetailsAction>
): GingrProfileDetailsState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_GINGR_PROFILE_DETAILS':
        const addGingrProfileDetailsAction =
          action as AddGingrProfileDetailsAction;
        const {
          id,
          slug,
          email,
          proUser,
          displayname,
          dateOfBirth,
          birthYear,
          gender,
          nationality,
          sexuality,
          food,
          drinks,
          interests,
          smoke,
          alcohol,
          createdAt,
          phonePrefix,
          phoneNumber,
          occupation,
          profileStatus,
          description,
          booking_notes,
          traveltypes,
          pricing,
          availability,
          services,
          languages,
          location,
          physicalDetails,
          avatar,
          verified
        } = addGingrProfileDetailsAction;
        console.log('Action: ', action);
        return {
          id,
          slug,
          email,
          proUser,
          displayname,
          dateOfBirth,
          birthYear,
          gender,
          nationality,
          sexuality,
          food,
          drinks,
          interests,
          smoke,
          alcohol,
          createdAt,
          phonePrefix,
          phoneNumber,
          occupation,
          profileStatus,
          description,
          booking_notes,
          traveltypes,
          pricing,
          availability,
          services,
          languages,
          location,
          physicalDetails,
          avatar,
          verified
        };
      case 'CLEAR_GINGR_PROFILE_DETAILS':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
