import { GeneralGingrProfileDetailsState } from './gingrProfileDetails.reducer';
import { GingrClient } from '../../../../clients/GingrClient';
import { BaseService } from '../../../../service/BaseService';
import { GingrImagesToBeApproved } from './GingrPhotos/userPhotos.reducer';
import { APPROVAL_STATUS } from './GingrDetails';
import {UserVerification} from "./GingrVerification/userVerification.reducer";

const ProductServicePaths = {
  GINGR_PROFILE_DETAILS: '/admin/users/gingr/',
  GINGR_IMAGES: (id: number | string, type: APPROVAL_STATUS) =>
    `/admin/medias/users/${id}/image?approved=${type}`,
  GINGR_VIDEOS: (id: string, type?: APPROVAL_STATUS) =>
    `/admin/medias/users/${id}/video?approved=${type}`,
  APPROVE_IMAGES: (id: string) => `/admin/medias/users/${id}/approve`,
  APPROVE_VIDEOS: (id: string) => `/admin/medias/users/${id}/approve`,
  APPROVAL_PROCESS: (id: string) => `/admin/users/${id}/approvable`,
  REJECT_PHOTOS: (id: string) => `/admin/medias/users/${id}/reject`,
  GINGR_VERIFICATION : (id: string) =>
      `/admin/users/${id}/idverification`,
  APPROVE_VERIFICATION: (id: string) => `/admin/users/${id}/idverification/approve`,
  REJECT_VERIFICATION:  (id: string) => `/admin/users/${id}/idverification/reject`,
};

export class GingrProfileDetailsService {
  static async getGingrProfileDetails(
    credentials: {
      token: string;
    },
    id: any
  ): Promise<GeneralGingrProfileDetailsState> {
    const { result } = await GingrClient.get(
      ProductServicePaths.GINGR_PROFILE_DETAILS + id,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async getGingrImages(
    credentials: {
      token: string;
    },
    id: number | string,
    type: APPROVAL_STATUS
  ): Promise<Array<GingrImagesToBeApproved> | null> {
    if (id) {
      const { result } = await GingrClient.get(
        ProductServicePaths.GINGR_IMAGES(id, type),
        BaseService.getBaseHeaders(credentials.token)
      );
      console.log(await GingrClient.get(
          ProductServicePaths.GINGR_IMAGES(id, type),
          BaseService.getBaseHeaders(credentials.token)
      ))
      return result;
    }
    return null;
  }
  static async getGingrVideos(
    credentials: {
      token: string;
    },
    id: number | string,
    type: APPROVAL_STATUS
  ): Promise<Array<{
    id: number;
    user_id: number;
    type: string;
    approved: number;
    avatar: number;
    feature: number;
    cover: number;
    active: number;
    censored: number;
    video: number;
    censored_from?: any;
    path?: any;
    checksum?: any;
    disabled: number;
    priority?: any;
    banner: number;
    media: string;
    thumb: string;
    thumbblur: string;
  }> | null> {
    if (id) {
      const { result } = await GingrClient.get(
        ProductServicePaths.GINGR_VIDEOS(id, type),
        BaseService.getBaseHeaders(credentials.token)
      );

      return result;

    }
    return null;
  }

  static async getGingrVerification(
      credentials: {
        token: string;
      },
      id: number | string,
  ): Promise<any> {
    if (id) {
      let  user  = await GingrClient.get(
          ProductServicePaths.GINGR_VERIFICATION(id),
          BaseService.getBaseHeaders(credentials.token)
      );

      let userArray = [user]
      return userArray;
    }
    return null;
  }


  static async approveImages(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<Array<GingrImagesToBeApproved>> {
    const { result } = await GingrClient.post(
      ProductServicePaths.APPROVE_IMAGES(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async approveVideos(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<Array<GingrImagesToBeApproved>> {
    const { result } = await GingrClient.post(
      ProductServicePaths.APPROVE_IMAGES(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async getApprovalProcess(
    credentials: {
      token: string;
    },
    id: any
  ): Promise<boolean> {
    const { result } = await GingrClient.get(
      ProductServicePaths.APPROVAL_PROCESS(id),
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async rejectImages(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<boolean> {
    const { result } = await GingrClient.post(
      ProductServicePaths.REJECT_PHOTOS(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async rejectVideos(
    id,
    credentials: {
      token: string;
    },
    body
  ): Promise<boolean> {
    const { result } = await GingrClient.post(
      ProductServicePaths.REJECT_PHOTOS(id),
      body,
      BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }


  static async approveSelfies(
      id,
      credentials: {
        token: string;
      },
      body
  ): Promise<Array<UserVerification>> {
    const { result } = await GingrClient.post(
        ProductServicePaths.APPROVE_VERIFICATION(id),
        body,
        BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }

  static async rejectSelfies(
      id,
      credentials: {
        token: string;
      },
      body
  ): Promise<boolean> {
    const { result } = await GingrClient.post(
        ProductServicePaths.REJECT_VERIFICATION(id),
        body,
        BaseService.getBaseHeaders(credentials.token)
    );
    return result;
  }
}
